import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "uninstallableMessage", "uninstallableMessageIos", "uninstallableMessageSafari", "downloadButton" ]
  
  installPromptEvent = null; 

  connect() {
    this.initializePwa();
  }

  // Check if installable and show message if not. 
  initializePwa() {
    // Check if PWA is already installed (optional)
    if (window.matchMedia('(display-mode: standalone)').matches) {
      console.log("PWA is already installed.");
      alert("PWA is already installed.");
      return;
    }

    let installPromptFired = false;

    const installCheck = setTimeout(() => {
      if (!installPromptFired) {
        this.handlePWACantInstall();
      }
    }, 1000);

    window.addEventListener('beforeinstallprompt', (event) => {
      clearTimeout(installCheck);
      installPromptFired = true;

      event.preventDefault();
      this.installPromptEvent = event;
    });
  }

  // Handle PWA installation on iOS
  handleIOS() {
    const userAgent = navigator.userAgent;
    const isIos = /iPhone|iPad/i.test(userAgent);
    const isSafari = /Safari/.test(userAgent) && /Chrome/.test(userAgent) === false;

    if (isIos && isSafari) {
      console.log("This is an iOS device using Safari");
      return "IOS";
    } else if (isSafari){
      console.log("This is an iOS device using Safari");
      return "Safari";  
    } else {
      console.log("This is not an iOS device or not using Safari");
      return false;
    }
  };
  

  // Cannot install, show cannot install message
  handlePWACantInstall() {
    console.log('Your PWA is not installable!');
    const pwaMessage = this.uninstallableMessageTarget;
    const downloadButton = this.downloadButtonTarget;
    if (pwaMessage && downloadButton && !this.handleIOS()) {
      pwaMessage.style.display = 'block';
      downloadButton.style.display = 'none';
    } else if (this.handleIOS() === "Safari") {
      const pwaMessageSafari = this.uninstallableMessageSafariTarget;
      const downloadButton = this.downloadButtonTarget;
      if (pwaMessageSafari && downloadButton) {
        pwaMessageSafari.style.display = 'block';
        downloadButton.style.display = 'none';
      }
    } else if (this.handleIOS() === "IOS") {
      const pwaMessageIos = this.uninstallableMessageIosTarget;
      const downloadButton = this.downloadButtonTarget;
      if (pwaMessageIos && downloadButton) {
        pwaMessageIos.style.display = 'block';
        downloadButton.style.display = 'none';
      }
    }  
  };

  installPwa() {
    console.log("Installing PWA!!!");
    if (!this.installPromptEvent) {
      console.error('No install prompt event available');
      return;
    };
    
    this.installPromptEvent.prompt();
    this.installPromptEvent.userChoice.then((choice) => {
      if (choice.outcome === 'accepted') {
        console.log('User accepted the install prompt');
        window.location.href = "/"
      } else {
        console.log('User dismissed the install prompt');
      }
      this.installPromptEvent = null;
    });
  }

}
