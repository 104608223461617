import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "input"]

  connect() {
    this.inputTargets.forEach((input, index) => {
      input.addEventListener('keyup', (e) => this.handleKeyUp(e, index))
      input.addEventListener('keydown', (e) => this.handleKeyDown(e, index))
    })
  }

  handleKeyUp(event, index) {
    const input = event.target
    input.value = input.value.toUpperCase()
    
    if (input.value && index < this.inputTargets.length - 1) {
      this.inputTargets[index + 1].focus()
    }

    if (this.inputTargets.every(input => input.value)) {
      this.formTarget.submit()
    }
  }

  handleKeyDown(event, index) {
    if (event.key === 'Backspace' && !event.target.value && index > 0) {
      this.inputTargets[index - 1].focus()
    }
  }

} 