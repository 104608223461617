import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Add constants at the top of the class
  static SCANNER_VERSION = "0.1.0";
  static SCAN_GRACE_PERIOD = 2000; // 2 seconds
  static FLASH_TIME = 2000; // 2 seconds
  static OFFLINE_CHECK_INTERVAL = 3000; // 3 seconds
  static SCANNER_SYNC_INTERVAL = 30000; // 30 seconds

  static targets = [
    "syncIcon",
    "guestIcon",
    "message",
    "footer",
    "connection",
    "lastGuestSync",
    "lastScannerSync",
    "offlineBanner",
    "syncBanner",
    "installButton",
    "offlineRecords",
    "notificationBanner",
    "notificationMessage",
    "guestSyncBtn",
    "scanSyncBtn",
    "cameraLoading",
    "alertMessage",
    "alertButtons",
    "alertConfirm",
    "alertCancel",
    "muteIcon"
  ]

  get lastGuestSync() {
    const value = localStorage.getItem(`lastGuestSync_${this.scannerId}`);
    return value ? parseInt(value) : null;
  }

  set lastGuestSync(value) {
    if (value) {
      localStorage.setItem(`lastGuestSync_${this.scannerId}`, value.toString());
    } else {
      localStorage.removeItem(`lastGuestSync_${this.scannerId}`);
    }
  }

  installPromptEvent = null; // to store the beforeinstallprompt event

  connect() {
    this.initializeScanVersion().then(() => {
      this.initializeScanner();
      Promise.all([
        this.initializeScanDb(),
        this.initializeGuestDb()
      ]).catch(error => {
        console.error("Database initialization failed:", error);
        this.flashError("Database initialization failed. Some features may not work.");
      }).finally(() => {
        this.startCamera();
        this.initializePwaPrompt();
        this.initializeConnectionStatus();
      });
      this.initializeAudio();
      this.initializePullToRefresh();
      this.updateDeviceInfo();
    });
  }

  async initializeScanVersion() {
    const currentVersion = localStorage.getItem('scanVersion');
    if (!currentVersion) {
      localStorage.setItem('scanVersion', this.constructor.SCANNER_VERSION);
    } else if (currentVersion !== this.constructor.SCANNER_VERSION) {
      localStorage.setItem('scanVersion', this.constructor.SCANNER_VERSION);
      const url = new URL(window.location.href);
      url.searchParams.set('reset', 'true');
      window.location.href = url.href;
    }
  }

  initializePwaPrompt() {
    // Check if app is installed using window.matchMedia
    const isInstalled = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://');

    if (isInstalled) {
      localStorage.setItem('pwaInstalled', 'true');
      return;
    }

    // Listen for install prompt
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.installPromptEvent = event;
      this.showInstallPwa();
    });
  }

  initializeScanner() {
    this.html5QrCode = new Html5Qrcode("scanner")
    this.qrConfig = {
      fps: 10,
      qrbox: {
        width: 350,
        height: 350
      },
      aspectRatio: 1.0
    }
    this.isScanning = false
    this.scannerId = this.element.dataset.scannerId;
    this.deviceId = this.element.dataset.deviceId;
    this.recordCounts = document.getElementsByClassName("record-count");
    this.guestCounts = document.getElementsByClassName("guest-count");
    this.lastScannedCode = null
    this.lastScannedTime = 0
    document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
  }


  initializeAudio() {
    this.audioContext = null;
    this.successSound = null;
    this.errorSound = null;

    // Check if browser supports Web Audio API
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!AudioContext) {
      console.log("Web Audio API not supported");
      this.showMutedIcon();
      return;
    }

    this.audioContext = new AudioContext();
    
    // Try to initialize sounds
    this.initializeSounds().then(() => {
      console.log("Audio context state:", this.audioContext.state);
      
      // Only show muted icon on iOS if context is suspended
      // const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (this.audioContext.state === 'suspended') {
        this.showMutedIcon();
      }
    });
  }

  async initializeSounds() {
    try {
      // Fetch and decode both sounds
      const [successBuffer, errorBuffer] = await Promise.all([
        this.fetchAndDecodeAudio('/sounds/ting.mp3'),
        this.fetchAndDecodeAudio('/sounds/error.mp3')
      ]);
      
      this.successSound = successBuffer;
      this.errorSound = errorBuffer;
      console.log(" Sounds initialized successfully");
    } catch (error) {
      console.error("Failed to initialize sounds:", error);
      this.showMutedIcon();
    }
  }

  async fetchAndDecodeAudio(url) {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    
    // Handle Safari's lack of Promise support for decodeAudioData
    return new Promise((resolve, reject) => {
      this.audioContext.decodeAudioData(
        arrayBuffer,
        (buffer) => resolve(buffer),
        (error) => reject(error)
      );
    });
  }

  showMutedIcon() {
    console.log("Showing muted icon");
    this.muteIconTarget.classList.remove('is-hidden');
  }

  hideMutedIcon() {
    console.log("Hiding muted icon");
    this.muteIconTarget.classList.add('is-hidden');
  }

  async unlockAudio() {
    if (!this.audioContext) return;
    
    try {
      await this.audioContext.resume();
      
      // Create and play a silent buffer to unlock audio
      const buffer = this.audioContext.createBuffer(1, 1, 22050);
      const source = this.audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(this.audioContext.destination);
      source.start(0);
      
      // If we get here, audio is unlocked
      this.hideMutedIcon();
      
      // Reinitialize sounds if needed
      if (!this.successSound || !this.errorSound) {
        await this.initializeSounds();
      }
    } catch (error) {
      console.error("Failed to unlock audio:", error);
    }
  }

  playSound(buffer) {
    if (!this.audioContext || !buffer) return;
    
    try {
      const source = this.audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(this.audioContext.destination);
      source.start(0);

      if (this.audioContext.state === 'running') {
        this.hideMutedIcon();
      }

    } catch (error) {
      console.error("Error playing sound:", error);
    }
  }

  initializeConnectionStatus() {
    this.checkConnectionStatus();
    window.addEventListener('online', this.checkConnectionStatus.bind(this));
    window.addEventListener('offline', this.checkConnectionStatus.bind(this));
    this.connectionCheckInterval = null;
  }

  initializeScanDb() {
    return new Promise((resolve, reject) => {
      const dbName = 'ScanRecordsDB';
      const dbVersion = 1;
      const request = indexedDB.open(dbName, dbVersion);

      request.onerror = (event) => {
        console.error("Error initializing ScanRecordsDB");
        reject(event.target.error);
      };

      request.onsuccess = (event) => {
        console.log("Successfully initialized ScanRecordsDB");
        this.recordDb = event.target.result;
        this.updateRecordCount();
        resolve();
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        const store = db.createObjectStore('records', { keyPath: 'reference' });
        store.createIndex('reference', 'reference', { unique: true });
      };
    });
  }

  initializeGuestDb() {
    return new Promise((resolve, reject) => {
      const dbName = `GuestRecordsDB_${this.scannerId}`; // Make DB name unique per scanner
      const dbVersion = 1;

      // Close existing connection if it exists and scanner ID changed
      if (this.guestDb && this.currentScannerId !== this.scannerId) {
        this.guestDb.close();
        this.guestDb = null;
      }

      const request = indexedDB.open(dbName, dbVersion);

      request.onerror = (event) => {
        console.error("Error initializing GuestRecordsDB");
        reject(event.target.error);
      };

      request.onsuccess = (event) => {
        console.log(`Successfully initialized GuestRecordsDB`);
        this.batchCount = 0;
        this.guestRetryCount = 0;
        this.guestDb = event.target.result;
        this.currentScannerId = this.scannerId; // Store current scanner ID
        this.updateGuestCount();
        resolve();
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        const store = db.createObjectStore('guests', { keyPath: 'qr_code' });
        store.createIndex('qr_code', 'qr_code', { unique: true });
      };
    });
  }

  updateDeviceInfo() {
    try {
      this.deviceInfo = document.getElementById("device-info");
      this.browserInfo = document.getElementById("browser-info");

      if (!this.deviceInfo || !this.browserInfo) return;

      // Get device info
      const userAgent = navigator.userAgent;
      let deviceInfo = "Unknown Device";

      try {
        if (/iPhone/.test(userAgent)) {
          deviceInfo = "iPhone";
        } else if (/iPad/.test(userAgent)) {
          deviceInfo = "iPad";
        } else if (/Android/.test(userAgent)) {
          deviceInfo = "Android Device";
        } else if (/Macintosh/.test(userAgent)) {
          deviceInfo = "MacBook/iMac";
        } else if (/Windows/.test(userAgent)) {
          deviceInfo = "Windows PC";
        } else if (/Linux/.test(userAgent)) {
          deviceInfo = "Linux Device";
        }
        this.deviceInfo.textContent = deviceInfo;
      } catch (error) {
        console.error("Error setting device info:", error);
      }

      // Get browser info with version
      try {
        let browserInfo = "Unknown Browser";
        if (/Chrome/.test(userAgent) && !/Edg/.test(userAgent)) {
          const version = userAgent.match(/Chrome\/(\d+\.\d+)/);
          browserInfo = version ? `Chrome ${version[1]}` : "Chrome";
        } else if (/Firefox/.test(userAgent)) {
          const version = userAgent.match(/Firefox\/(\d+\.\d+)/);
          browserInfo = version ? `Firefox ${version[1]}` : "Firefox";
        } else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
          const version = userAgent.match(/Version\/(\d+\.\d+)/);
          browserInfo = version ? `Safari ${version[1]}` : "Safari";
        } else if (/Edg/.test(userAgent)) {
          const version = userAgent.match(/Edg\/(\d+\.\d+)/);
          browserInfo = version ? `Edge ${version[1]}` : "Edge";
        } else if (/Opera|OPR/.test(userAgent)) {
          const version = userAgent.match(/(?:Opera|OPR)\/(\d+\.\d+)/);
          browserInfo = version ? `Opera ${version[1]}` : "Opera";
        }
        this.browserInfo.textContent = browserInfo;
      } catch (error) {
        console.error("Error setting browser info:", error);
      }
    } catch (error) {
      console.error("Error in updateDeviceInfo:", error);
    }
  }

  checkConnectionStatus() {
    if (navigator.onLine) {
      this.pingServerForResponse();
    } else {
      this.updateConnectionStatus(false);
    }
  }

  async pingServerForResponse() {
    try {
      await fetch('/ping', { method: 'HEAD', cache: 'no-store' });
      this.updateConnectionStatus(true);
    } catch (error) {
      this.updateConnectionStatus(false);
    }
  }

  updateConnectionStatus(isConnected) {
    this.isConnected = isConnected;
    this.connectionTarget.classList.toggle('connected', isConnected);
    this.connectionTarget.classList.toggle('disconnected', !isConnected);
    clearInterval(this.connectionCheckInterval);
    if (isConnected) {
      console.log("✅ ✅ ✅ SCANNER ONLINE ✅ ✅ ✅");
      if (!this.lastGuestSync) {
        this.syncGuestDb();
      } else {
        this.updateSyncTime('guest', this.lastGuestSync);
      }
      this.processOfflineRecords();
      this.connectionCheckInterval = null;
      this.offlineBannerTarget.classList.toggle("is-hidden", true);
    } else {
      console.log("❌ ❌ ❌ SCANNER OFFLINE ❌ ❌ ❌");
      this.offlineBannerTarget.classList.toggle("is-hidden", false);
      this.connectionCheckInterval = setInterval(() => this.checkConnectionStatus(), this.constructor.OFFLINE_CHECK_INTERVAL);
    }
  }

  toggleSyncBanner(show) {
    this.syncBannerTarget.classList.toggle("is-hidden", !show);
  }

  retrySyncGuestDb() {
    if (this.guestRetryCount < 3) {
      this.guestRetryCount++;
      setTimeout(() => this.syncGuestDb(this.batchCount), 1000);
    } else {
      console.error("Failed to sync GuestRecordsDB");
    }
  }

  toggleSyncIcons(showSync) {
    if (showSync) {
      this.toggleSyncBanner(true);
      this.syncIconTarget.classList.remove("is-hidden");
      this.guestIconTarget.classList.add("is-hidden");
    } else {
      this.toggleSyncBanner(false);
      this.syncIconTarget.classList.add("is-hidden");
      this.guestIconTarget.classList.remove("is-hidden");
    }
  }

  syncGuestDb() {
    if (!this.guestDb) return;
    if (!this.isConnected) return this.retrySyncGuestDb();

    if (this._syncInProgress) return;  // Prevent parallel syncs
    this._syncInProgress = true;

    if (this.batchCount === 0) { console.log("🔄 🔄 🔄 Syncing GuestRecordsDB 🔄 🔄 🔄"); }

    // Reset retry count
    this.guestRetryCount = 0;

    // Show sync icon
    this.toggleSyncIcons(true);

    this.setButtonLoading('guest', true);

    const url = `/${this.scannerId}/sync/guests${this.batchCount ? `?batch=${this.batchCount}` : ''}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        if (data.complete) {
          this.setupScannerSync();
          this.toggleSyncIcons(false);
          // Update sync time for guest only if sync was complete.
          this.updateSyncTime('guest');
          this.updateSyncTime('scanner'); // Technically this happens too
          this.setButtonLoading('guest', false);
          this.batchCount = 0;
        } else {
          this.batchCount++;
          this.addLocalGuests(data.guests)
            .then(() => {
              console.log(`👤 Imported ${data.guests.length} guests to GuestRecordsDB`);
              this.updateGuestCount();
              this.syncGuestDb(this.batchCount);
            });
        }
      } else {
        this.toggleSyncIcons(false);
        this.setButtonLoading('guest', false);
        console.error("Error syncing GuestRecordsDB");
      }
    })
    .catch(error => {
      this.toggleSyncIcons(false);
      this.checkConnectionStatus();
      this.setButtonLoading('guest', false);
      console.error("Error syncing GuestRecordsDB: ", error);
    })
    .finally(() => {
      this._syncInProgress = false;
    });
  }

  updateSyncTime(type, timestamp = Date.now()) {
    const syncProperty = type === 'guest' ? 'lastGuestSync' : 'lastScannerSync';
    const syncTarget = type === 'guest' ? this.lastGuestSyncTarget : this.lastScannerSyncTarget;
    this[syncProperty] = timestamp;
    console.log(`🕒 Last ${type} sync: ${timestamp ? new Date(timestamp).toLocaleString() : "Never"}`);
    syncTarget.textContent = timestamp ? new Date(timestamp).toLocaleString() : "Never";
  }

  reSyncGuestDb() {
    this.showAlert('This will re-import all guests onto this device. Are you sure?', {
      confirm: true,
      confirmText: 'Re-sync',
      cancelText: 'Cancel'
    }).then((confirmed) => {
      if (!confirmed) return;

      if (!this.isConnected) {
        this.showAlert("You must be online to resync the guest database.");
        return;
      }

      // Clear the guest database first
      const transaction = this.guestDb.transaction(['guests'], 'readwrite');
      const store = transaction.objectStore('guests');
      const clearRequest = store.clear();

      clearRequest.onsuccess = () => {
        console.log('🗑️ All GuestRecordsDB Records Deleted');
        this.updateGuestCount();
        this.guestRetryCount = 0;
        this.batchCount = 0;
        this.syncGuestDb();
      };

      clearRequest.onerror = (event) => {
        console.error('Error clearing guest database:', event.target.error);
        this.setButtonLoading('guest', false);
      };
    });
  }

  syncScannerRecords() {
    if (!this.recordDb) return;
    if (!this.isConnected) {
      this.showAlert("You must be online to sync the scanner records.");
      return;
    }

    this.updateOfflineRecordCount();
    this.setButtonLoading('scan', true);

    const url = `/${this.scannerId}/sync/records?device_id=${this.deviceId}${this.lastScannerSync ? `&since=${this.lastScannerSync}` : ''}`;
    const timeoutDuration = 10000; // 10 seconds timeout
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Request timeout')), timeoutDuration);
    });

    const fetchPromise = fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    });

    Promise.race([fetchPromise, timeoutPromise])
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log(`Synced ${data.records.length} records`);
        this.updateSyncTime('scanner');

        data.records.forEach(record => {
          this.checkInLocalGuest(record);
        });
        this.setButtonLoading('scan', false);
      })
      .catch((error) => {
        console.log("Failed to sync records:", error.message);
        // Only check connection if it's a network error or timeout
        if (error.name === 'TypeError' || error.message === 'Request timeout') {
          this.checkConnectionStatus();
        }
        this.setButtonLoading('scan', false);
      });
  }

  setButtonLoading(target, isLoading) {
    const btn = this[`${target}SyncBtnTarget`];
    if (isLoading) {
      btn.textContent = "syncing";
      btn.style.color = "#999999";
      btn.style.pointerEvents = "none";
    } else {
      btn.textContent = "resync";
      btn.style.color = "";
      btn.style.pointerEvents = "";
    }
  }

  addLocalGuests(data) {
    const transaction = this.guestDb.transaction(['guests'], 'readwrite');
    const store = transaction.objectStore('guests');
    return Promise.all(
      data.map(guest =>
        new Promise((resolve, reject) => {
          const request = store.put(guest);
          request.onsuccess = () => resolve();
          request.onerror = () => reject(request.error);
        })
      )
    );
  }

  checkInLocalGuest(guestCode) {
    this.getGuestRecord(guestCode).then(guestRecord => {
      if (!guestRecord) return;  // Exit if no record found
      if (guestRecord.checked_in) return; // Do nothing if already checked in
      guestRecord.checked_in = true;
      const transaction = this.guestDb.transaction(['guests'], 'readwrite');
      const store = transaction.objectStore('guests');
      const request = store.put(guestRecord);
      request.onerror = (event) => {
        console.error("Error checking in guest:", event.target.error);
      };
    });
  }

  updateGuestCount() {
    this.updateCount(this.guestDb, 'guests', Array.from(this.guestCounts));
  }

  updateRecordCount() {
    this.updateCount(this.recordDb, 'records', Array.from(this.recordCounts));
  }

  updateOfflineRecordCount() {
    const request = this.fetchScannerRecords();
    request.onsuccess = () => {
      const records = request.result;
      const offlineRecords = records.filter(record => !record.sent_to_server);
      this.offlineRecordsTarget.textContent = offlineRecords.length.toLocaleString();
      const wrapper = document.getElementById("offline-records-wrapper");
      wrapper.classList.toggle("is-hidden", offlineRecords.length === 0);
    };
  }

  updateCount(db, storeName, elements) {
    // Convert single element to array if needed
    const elementArray = Array.isArray(elements) ? elements : [elements];

    const transaction = db.transaction([storeName], 'readonly');
    const store = transaction.objectStore(storeName);
    const countRequest = store.count();

    countRequest.onsuccess = () => {
      const count = countRequest.result;
      const displayText = count.toLocaleString(); // Formats number with commas
      elementArray.forEach(element => {
        if (element) element.textContent = displayText;
      });
    };
  }

  startCamera() {
    if (this.isScanning || this._startingCamera) return;
    
    this._startingCamera = true;
    this.cameraLoadingTarget.classList.remove('is-hidden');

    this.html5QrCode
      .start(
        { facingMode: "environment" },
        this.qrConfig,
        this.createNewScanRecord
      )
      .then(() => {
        this.isScanning = true;
        this.cameraLoadingTarget.classList.add('is-hidden');
      })
      .catch((err) => {
        this.cameraError(err);
        this.isScanning = false;
        this.cameraLoadingTarget.classList.add('is-hidden');
      })
      .finally(() => {
        this._startingCamera = false;
      });
  }

  stopCamera() {
    if (!this.isScanning || this._stoppingCamera) return Promise.resolve();
    
    this._stoppingCamera = true;
    this.isScanning = false;
    this.cameraLoadingTarget.classList.add('is-hidden');

    if (this.html5QrCode) {
      return this.html5QrCode.stop()
        .catch((err) => {
          console.error("Error stopping camera:", err);
        })
        .finally(() => {
          this._stoppingCamera = false;
        });
    }
    
    this._stoppingCamera = false;
    return Promise.resolve();
  }

  handleVisibilityChange() {
    if (document.hidden) {
      this.stopCamera();
    } else {
      if (!this.isScanning) {
        setTimeout(() => { this.startCamera(); }, 500);
      }
    }
  }

  async resetCamera() {
    if (!this.isScanning) return;
    
    await this.stopCamera();
    await new Promise(resolve => setTimeout(resolve, 500));
    this.startCamera();
  }

  cameraError(message) {
    console.error("Camera error:", message);
    this.flashError("Camera error occurred. Attempting to reset...");
    this.resetCamera();
  }

  createNewScanRecord = (decodedText, _) => {
    const now = Date.now()
    if (!decodedText || !this.isScanning) return
    if (decodedText === this.lastScannedCode && now - this.lastScannedTime < this.constructor.SCAN_GRACE_PERIOD) return

    this.isScanning = false
    this.lastScannedCode = decodedText
    this.lastScannedTime = now

    // Create the record data object
    const recordData = {
      reference: crypto.randomUUID(),
      scanner_id: this.scannerId,
      data: decodedText,
      datetime: new Date().toISOString(),
      sent_to_server: false
    };

    // Add the record to local storage
    this.addLocalRecord(recordData);

    // Process record and wait before allowing new scans
    this.processRecordData(recordData)
      .then(() => {
        setTimeout(() => {
          this.isScanning = true;
        }, this.constructor.SCAN_GRACE_PERIOD);
      });
  }

  addLocalRecord(recordData) {
    const transaction = this.recordDb.transaction(['records'], 'readwrite');
    const store = transaction.objectStore('records');
    const request = store.add(recordData);

    request.onerror = (event) => {
      console.error("Error adding record:", event.target.error);
    };

    request.onsuccess = () => {
      console.log(`Record ${recordData.reference} added successfully`);
      this.updateRecordCount();
      this.updateOfflineRecordCount();
    };
  }

  localRecordSentToServer(recordReference) {
    const transaction = this.recordDb.transaction(['records'], 'readwrite');
    const store = transaction.objectStore('records');
    const request = store.get(recordReference);

    request.onerror = (event) => {
      console.error("Error removing record:", event.target.error);
    };

    request.onsuccess = () => {
      const record = request.result;
      record.sent_to_server = true;
      const updateRequest = store.put(record);
      this.updateOfflineRecordCount();
      updateRequest.onerror = (event) => {
        console.error("Error updating record:", event.target.error);
      };
    };
  }

  async processRecordData(recordData) {
    const guestCode = recordData.data;
    let accessGranted = false;
    // Please make sure these messages align with the ones in the scanner_records_controller.rb file
    let message = null;

    try {
      // Check local guest database first
      const localGuest = await this.getGuestRecord(guestCode);

      if (localGuest) {
        console.log(`Local guest found: ${JSON.stringify(localGuest)}`);
        accessGranted = localGuest.multiple_entry || !localGuest.checked_in;
        if (accessGranted) {
          this.checkInLocalGuest(guestCode);
        } else {
          message = "ALREADY ENTERED";
        }
        this.sendToServer(recordData);
      } else {
        try {
          console.log(`Local guest not found, sending record to server: ${JSON.stringify(recordData)}`);
          const response = await this.sendToServer(recordData);
          accessGranted = response.success;
          message = response.message;
        } catch (error) {
          console.error("Server verification failed:", error);
          accessGranted = true; // Fallback to allowing access on server error
        }
      }
    } catch (error) {
      console.error("Error processing record:", error);
      accessGranted = true; // Fallback to allowing access on any error
    }

    // Flash appropriate message
    if (accessGranted) {
      message = message || "ACCESS GRANTED";
      this.flashSuccess(message);
    } else {
      message = message || "ACCESS DENIED";
      this.flashError(message);
    }
  }

  async sendToServer(recordData) {
    console.log(`Sending record to server: ${JSON.stringify(recordData)}`);

    // Add device id to record data
    recordData.device_id = this.deviceId;

    const url = `/${this.scannerId}/record`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(recordData)
    };

    try {
      const response = await Promise.race([
        fetch(url, options),
        this.createTimeout(2000)
      ]);
      const data = await response.json();
      console.log(`Server response: ${JSON.stringify(data)}`);
      this.localRecordSentToServer(recordData.reference);
      return data;
    } catch (error) {
      this.checkConnectionStatus();
      throw new Error(`Failed to send record: ${error.message}`);
    }
  }

  createTimeout(duration) {
    return new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Request timeout')), duration);
    });
  }

  getGuestRecord(guestCode) {
    return new Promise((resolve) => {
      const transaction = this.guestDb.transaction(['guests'], 'readonly');
      const store = transaction.objectStore('guests');
      const request = store.get(guestCode);

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        console.error("Error checking guest database:", event.target.error);
        resolve(null);  // Return null instead of rejecting
      };
    });
  }

  flashSuccess(message) {
    this.clearTimeout();
    this.messageTarget.textContent = this.lastScannedCode;

    // Show notification banner
    this.notificationBannerTarget.classList.remove("is-hidden", "fail");
    this.notificationBannerTarget.classList.add("success");
    this.notificationMessageTarget.textContent = message;

    // Play success sound
    this.playSound(this.successSound);

    this.flashTimeout = setTimeout(() => {
      this.messageTarget.html = "SCAN A QR CODE";
      this.footerTarget.classList.remove("success");
      this.notificationBannerTarget.classList.add("is-hidden");
    }, this.constructor.FLASH_TIME);
  }

  flashError(message) {
    this.clearTimeout();
    this.messageTarget.textContent = this.lastScannedCode;

    // Show notification banner
    this.notificationBannerTarget.classList.remove("is-hidden", "success");
    this.notificationBannerTarget.classList.add("fail");
    this.notificationMessageTarget.textContent = message;

    // Play error sound
    this.playSound(this.errorSound);

    this.flashTimeout = setTimeout(() => {
      this.messageTarget.textContent = "SCAN A QR CODE";
      this.footerTarget.classList.remove("error");
      this.notificationBannerTarget.classList.add("is-hidden");
    }, this.constructor.FLASH_TIME);
  }

  clearTimeout() {
    if (this.flashTimeout) clearTimeout(this.flashTimeout);
  }

  fetchScannerRecords() {
    const transaction = this.recordDb.transaction(['records'], 'readonly');
    const store = transaction.objectStore('records');
    const request = store.getAll();
    return request;
  }

  processOfflineRecords() {
    if (!this.recordDb) return;

    console.log("Checking for offline records");
    const request = this.fetchScannerRecords();

    request.onsuccess = () => {
      const records = request.result;
      const offlineRecords = records.filter(record => !record.sent_to_server);

      if (offlineRecords.length === 0) {
        console.log("No offline records found");
        return;
      } else {
        console.log(`Found ${offlineRecords.length} offline records`);
      }

      // Send records sequentially with delay
      const sendRecordsSequentially = (records, index = 0) => {
        if (index >= records.length) {
          console.log(`Finished sending ${records.length} offline records to server`);
          return;
        }

        this.sendToServer(records[index])
          .then(() => {
            this.updateOfflineRecordCount();
            console.log(`Sent record ${records[index].reference}`);
            setTimeout(() => {
              sendRecordsSequentially(records, index + 1);
            }, 1000); // 1 second delay
          })
          .catch(error => {
            console.error(`Error sending offline record: ${error}`);
            // Continue with next record even if current one fails
            setTimeout(() => {
              sendRecordsSequentially(records, index + 1);
            }, 1000);
          });
      };

      if (offlineRecords.length > 0) {
        console.log(`Starting to send ${offlineRecords.length} offline records to server`);
        sendRecordsSequentially(offlineRecords);
      }
    };
  }

  setupScannerSync() {
    if (this.scannerSyncInterval) clearInterval(this.scannerSyncInterval);
    this.scannerSyncInterval = setInterval(() => {
      this.syncScannerRecords();
    }, this.constructor.SCANNER_SYNC_INTERVAL);
  }

  disconnect() {
    if (this.scannerSyncInterval) clearInterval(this.scannerSyncInterval);
    this.stopCamera();
    document.removeEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
  }

  toggleDialog(event) {
    const dialogId = event.currentTarget.dataset.dialogId;
    const dialog = document.getElementById(dialogId);
    if (dialog.open) dialog.close();
    else dialog.showModal();
  }

  shareScanner() {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        this.showAlert('Scanner link copied to clipboard!');
      })
    .catch(console.error);
  }

  showInstallPwa() {
    const pwaDialog = document.getElementById('pwa-dialog');
    if (pwaDialog && !localStorage.getItem('pwaDialogDismissed')) {
      pwaDialog.showModal();
    }
  }

  closePwaDialog() {
    const pwaDialog = document.getElementById('pwa-dialog');
    if (pwaDialog) {
      pwaDialog.close();
      localStorage.setItem('pwaDialogDismissed', 'true');
    }
  }

  installPwa() {
    console.log("Installing PWA!!!");
    if (!this.installPromptEvent) return;

    const pwaDialog = document.getElementById('pwa-dialog');
    if (pwaDialog) pwaDialog.close();

    this.installPromptEvent.prompt();
    this.installPromptEvent.userChoice.then((choice) => {
      if (choice.outcome === 'accepted') {
        console.log('User accepted the install prompt');
        localStorage.setItem('pwaInstalled', 'true');
      } else {
        console.log('User dismissed the install prompt');
      }
      this.installPromptEvent = null;
    });
  }

  copyInfo(event) {
    const info = {
      scanner_id: this.scannerId,
      device_id: this.deviceId,
      device_info: document.getElementById('device-info').textContent,
      browser_info: document.getElementById('browser-info').textContent,
    };

    navigator.clipboard.writeText(JSON.stringify(info)).then(() => {
      const copyButton = event.target;
      const originalContent = copyButton.innerHTML;
      // Keep the SVG icon when showing the "Copied!" message
      const svgIcon = copyButton.querySelector('svg');
      copyButton.innerHTML = '';
      if (svgIcon) copyButton.appendChild(svgIcon);
      copyButton.appendChild(document.createTextNode(' Copied!'));

      setTimeout(() => {
        copyButton.innerHTML = originalContent;
      }, 500);

    });
  }

  initializePullToRefresh() {
    let startY = 0;
    const minPullDistance = 150;
    const wrapper = this.element;

    wrapper.addEventListener('mousedown', (e) => {
      startY = e.clientY;
    });

    wrapper.addEventListener('touchstart', (e) => {
      startY = e.touches[0].clientY;
    }, { passive: true });

    wrapper.addEventListener('mouseup', (e) => {
      const pullDistance = e.clientY - startY;
      if (pullDistance > minPullDistance) {
        this.refreshPage();
      }
    });

    wrapper.addEventListener('touchend', (e) => {
      const pullDistance = e.changedTouches[0].clientY - startY;
      if (pullDistance > minPullDistance) {
        this.refreshPage();
      }
    });
  }

  refreshPage() {
    this.cameraLoadingTarget.classList.remove('is-hidden');
    window.location.reload();
  }

  showAlert(message, options = {}) {
    return new Promise((resolve) => {
      const dialog = document.getElementById('alert-dialog');
      this.alertMessageTarget.textContent = message;
      
      // Show/hide confirm buttons based on options
      if (options.confirm) {
        this.alertButtonsTarget.classList.remove('is-hidden');
        this.alertCancelTarget.textContent = options.cancelText || 'Cancel';
        this.alertConfirmTarget.textContent = options.confirmText || 'Confirm';
        this._alertResolve = resolve;
      } else {
        this.alertButtonsTarget.classList.add('is-hidden');
        this._alertResolve = null;
      }
      
      dialog.showModal();
    });
  }

  closeAlert() {
    const dialog = document.getElementById('alert-dialog');
    dialog.close();
    if (this._alertResolve) {
      this._alertResolve(false);
      this._alertResolve = null;
    }
  }

  handleAlertResponse(event) {
    const response = event.currentTarget.dataset.response === 'true';
    const dialog = document.getElementById('alert-dialog');
    dialog.close();
    
    if (this._alertResolve) {
      this._alertResolve(response);
      this._alertResolve = null;
    }
  }

}
