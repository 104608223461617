import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    uuid: String,
    resourceType: String
  }


  connect() {
    console.log("Connected scanner records download controller");
  }

  async download() {
    try {
      console.log(this.resourceTypeValue);
      const result = await whisper("/scanners/" + this.uuidValue  + "/download_scanner_records?resource_type=" + this.resourceTypeValue);

      if(result.download){
        notify_success(
          'Download processing<br><br>It will be emailed to <strong class="has-text-white">' +
            result.email +
            "</strong>."
        );
      } else {
        notify_danger("Download did not occur, please try again")
      }
    } catch (error) {
      console.log("Error: ", error);
      notify_danger("Error, please try again.");
    }
  }
}
