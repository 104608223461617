const initInlineSearch = () => {
  let debounceTimer;
  const debounceDuration = 500;

  // $(".svp-body").on("input", ".js-inline-search .search-input", function () {
  //   clearTimeout(debounceTimer);
  //   debounceTimer = setTimeout(() => {
  //     submitFormAjax($(this).closest('form')[0]);
  //   }, debounceDuration);
  // });

  $(".svp-body").on("keydown", ".js-inline-search .search-input", function (event) {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission
      const input_element = document.getElementById("booking-search");
      if (input_element.value.length > 2) {
        submitFormAjax($(this).closest('form')[0]);
      } else {
        notify_danger("Please enter at least 3 characters to search.");
      }
    }
  });

  $(".svp-body").on("click", ".js-inline-search-button", function (event) {
    event.preventDefault(); // Prevent default button action

    const input_element = document.getElementById("booking-search");
    if (input_element.value.length > 2) {
      submitFormAjax($(this).closest('form')[0]);
    } else {
      notify_danger("Please enter at least 3 characters to search.");
    }
  });

  $(".js-inline-search .dropdown-item").on("click", function (event) {
      event.preventDefault();
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      const clickedElement = event.target
      const scope = clickedElement.id;
      const resource = clickedElement.dataset.resource;

      // Set up correct search params
      params.set('status_scope', scope);

      params.set('resource', resource);
      params.delete('page');
      url.search = params.toString();

      // Update Url in bar
      let new_url = url.toString();
      history.pushState(null, null, new_url);


      clickedElement.href = `/search/bookings${url.search}`;
      clickedElement.click();

      // set new url in search bar without resource
      params.delete('resource');
      if (scope === "") {
        params.delete('status_scope');
      }
      url.search = params.toString();
      new_url = url.toString();
      history.pushState(null, null, new_url);
  });


  function submitFormAjax(form) {

    const svpBody = form.closest('.svp-body');
    const downloadButton = svpBody.querySelector('#bookings-download');
    const searchInputs = form.querySelectorAll('.search-input');

    replaceUrlParams(searchInputs);

    if (downloadButton) {
      console.log('disabling download button');
      downloadButton.classList.add('is-disabled');
    }

    // Extract status_scope and other query parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const statusScope = urlParams.get('status_scope');

    const formData = new FormData(form);

    if (statusScope) {
      formData.append('status_scope', statusScope);
    }

    document.querySelector('#loading-dots').classList.remove('is-hidden');

    $.ajax({
      url: form.getAttribute('action'),
      type: "POST",
      data: formData,
      processData: false,
      contentType: false,
      success: function(response) {
        // Handle success
      },
      error: function (response) {
        console.error(response);
      },
    });
  }


  // fetch search query params and add in query=@query
  function replaceUrlParams(inputs) {

    var url = new URL(window.location.href);
    var params = new URLSearchParams(url.search);

    inputs.forEach((input) => {
      if (input.value === "") {
        params.delete(input.name);
      } else {
        params.set(input.name, input.value);
      }
    });

    params.delete('page'); // Always reset page

    url.search = params.toString();
    var new_url = url.toString();
    history.pushState(null, null, new_url);

  }
};

export { initInlineSearch };
