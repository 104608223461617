import consumer from "./consumer"
import { displayFlashMessage } from "utils/flash";

/**
 * This file sets up a WebSocket connection to the NotificationsChannel for a specific user,
 * identified by their account ID. It is designed for use in non-main server processes to
 * trigger real-time notifications to specific users.
 */

let notificationsChannelSubscription = null;

export function createNotificationsChannelSubscription(id) {
  if (notificationsChannelSubscription) {
    return notificationsChannelSubscription;
  }

  notificationsChannelSubscription = consumer.subscriptions.create({ channel: "NotificationsChannel", id: id }, {
    connected() { },
    disconnected() { },
    received(data) {
      displayFlashMessage(data.message, data.type);
    },
  });

  return notificationsChannelSubscription;
}

document.addEventListener("turbolinks:load", () => {
  const channelContainer = document.querySelector(".notifications-channel-container");

  if (channelContainer) {
    const id = channelContainer.dataset.currentAccountId;
    createNotificationsChannelSubscription(id);
  }
});
